import React, { useState, useEffect } from "react"
import { firebaseApp } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import { useDb } from "../../contexts/DbContext"
import * as S from "./styles"
import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import Button from "../../components/Button"

export default function ModalPhoneUpdate(props) {
  const { close, visible, phoneNumber } = props
  const { currentUser, linkPhone, verifyPhone, updatePhone } = useAuth()
  const { updateUserPhoneNumber } = useDb()
  const [step, setStep] = useState(0)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({ phone: "", code: "" })
  const [verificationId, setVerificationId] = useState(null)
  const [pPCheck, setPPCheck] = useState(false)

  // Phone sent in props
  useEffect(() => {
    if (phoneNumber) {
      setForm({ ...form, phone: phoneNumber })
      handlePhoneSent(phoneNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber])

  const handlePhoneSent = async (phoneNumber) => {
    setLoading(true)

    if (form.phone) phoneNumber = form.phone.replace(/-|\s/g, "")
    else {
      phoneNumber = phoneNumber.replace(/-|\s/g, "")
      setForm({ ...form, phone: phoneNumber })
    }

    if (!/^\+[1-9]\d{10,14}$/.test(phoneNumber)) {
      setError("Nesprávný formát čísla")
      setLoading(false)
    } else {
      try {
        if (phoneNumber && currentUser.phoneNumber !== phoneNumber) {
          const recaptcha = new firebaseApp.auth.RecaptchaVerifier(
            "recaptchaContainer",
            {
              size: "invisible",
            }
          )

          if (currentUser.phoneNumber === null) {
            // Set new phone number
            setVerificationId(await linkPhone(phoneNumber, recaptcha))
          } else {
            // Update phone number
            setVerificationId(await verifyPhone(phoneNumber, recaptcha))
          }
        }
        setStep(1)
      } catch (error) {
        setError("Telefonní číslo se nepodařilo ověřit. " + error.message)
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleCodeSent = async () => {
    setLoading(true)

    if (form.code === null) {
      setError("Kód nemůže být prázdný")
      return
    }

    try {
      if (currentUser.phoneNumber === null) {
        // Set new phone number
        verificationId
          .confirm(form.code)
          .then((result) => {
            // Set phone in db (Auth already updated)
            updateUserPhoneNumber(currentUser.uid, result.user.phoneNumber)

            setSuccess(
              "Ověření telefonního čísla " +
                result.user.phoneNumber +
                " proběhlo v pořádku"
            )
          })
          .catch((error) => {
            if (error.code === "auth/credential-already-in-use") {
              setError(
                "Tohle telefonní číslo neumíme ověřit, protože už je propojené s jiným e-mailem. Zadej prosím jiné."
              )
            } else {
              setError("Tenhle kód nefunguje. Zkus ho zadat znovu.")
            }

            // TODO: Sent error to sentry
            console.error(error)
          })
      } else {
        // Update phone number
        try {
          const credential = firebaseApp.auth.PhoneAuthProvider.credential(
            verificationId,
            form.code
          )
          // Auth
          await updatePhone(credential)
          // Db
          updateUserPhoneNumber(currentUser.uid, form.phone)
          setSuccess("Změna telefonního čísla proběhla v pořádku")
        } catch (error) {
          setError("Tenhle kód nefunguje. Zkus ho zadat znovu.")
          console.error(error.message)
          setForm({ ...form, phone: currentUser.phoneNumber })
        }
      }
      setStep(2)
    } catch (error) {
      setError("Tenhle kód nefunguje. Zkus ho zadat znovu.")
      console.error(error.message)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return visible ? (
    <S.Backdrop>
      <S.Modal>
        <S.Close onClick={close}>✕</S.Close>
        {step === 0 && (
          <>
            <Grid>
              <S.Title>Pro ověření potřebujeme Tvoje telefonní číslo</S.Title>
            </Grid>
            <Grid>
              <S.MuiPhoneNumber
                label="Telefon"
                variant="outlined"
                disableAreaCodes={true}
                defaultCountry={"cz"}
                value={form.phone}
                onChange={(value) => setForm({ ...form, phone: value })}
                onClick={() => setError("")}
              />
            </Grid>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    name="gdpr_agreement"
                    color="primary"
                    onChange={() => setPPCheck(!pPCheck)}
                  />
                }
                label={
                  <p>
                    Souhlasím se&nbsp;
                    <a href="/content/privacy_policy.html" target="_blank">
                      zpracováním osobních údajů
                    </a>
                  </p>
                }
              />
            </Grid>
            <Grid>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button disabled={loading} color="gray" onClick={close}>
                  Později
                </Button>
                <Button
                  disabled={loading || !pPCheck}
                  color="primary"
                  onClick={() => handlePhoneSent(null)}
                >
                  Odeslat
                </Button>
              </Box>
            </Grid>
          </>
        )}
        {step === 1 && (
          <>
            <Grid>
              <S.Title>
                Zadej šestimístný kód, který Ti přišel jako SMS na Tvůj telefon
              </S.Title>
            </Grid>
            <Grid>
              <TextField
                fullWidth
                variant="outlined"
                label="Ověřovací kód"
                onChange={(e) => setForm({ ...form, code: e.target.value })}
                value={form.code}
                placeholder="123456"
                pattern="[0-9]{6}"
              />
            </Grid>
            <Grid>
              <Box mt={2} align="right">
                <Button
                  disabled={loading}
                  color="primary"
                  onClick={handleCodeSent}
                >
                  {loading ? "Čekejte" : "Odeslat"}
                </Button>
              </Box>
            </Grid>
          </>
        )}
        {step === 2 && (
          <>
            <Grid>
              <h2>{success || "Čekejte prosím"}</h2>
            </Grid>
            <Grid>
              <Box mt={2} align="right">
                <Button color="primary" onClick={close}>
                  Zavřít
                </Button>
              </Box>
            </Grid>
          </>
        )}
        {error !== "" && (
          <Grid>
            <Box mt={2} sx={{ color: "error.main" }}>
              <S.Error>{error}</S.Error>
            </Box>
          </Grid>
        )}
      </S.Modal>
      <div id="recaptchaContainer" />
    </S.Backdrop>
  ) : null
}
