import "date-fns"
import React, { useState, useEffect, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { useDb } from "../../contexts/DbContext"
import { useAuth } from "../../contexts/AuthContext"
import strToUrl from "../../helpers/strToUrl"
import "react-bootstrap-typeahead/css/Typeahead.css"
import {
  Grid,
  TextField,
  InputAdornment,
  FormHelperText,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import Button from "../../components/Button"
import Modal from "../../primitives/modal"
import Resizer from "react-image-file-resizer"
import * as S from "./styles"

const MAPYCZ_API_KEY = "RD5Am5LzqmSacYx8a9QRgVVvtWNbMLgdRPD1Y6veDTY"

const resizeBase64 = async (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri)
      },
      "base64"
    )
  })

const resizeFile = async (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri)
      },
      "file"
    )
  })

export const accomodationTypes = [
  { textId: "private", title: "V soukromí" },
  { textId: "rectory", title: "Fara" },
  { textId: "commercial", title: "Komerční ubytování" },
  { textId: "camping", title: "Zahrada/stan" },
  { textId: "other", title: "Jiné" },
]

export default function HostProfile() {
  const [error, setError] = useState("")
  const [validationErrors, setValidationErrors] = useState({})
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const [trails, setTrails] = useState([])
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [autocompleteOptions, setAutocompleteOptions] = useState([])
  const [autocompleteValue, setAutocompleteValue] = useState({})
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("")
  const [place, setPlace] = useState({})
  const [facilities, setFacilities] = useState([])
  const [facilitiesSelected, setFacilitiesSelected] = useState([])
  const [chnagingAddress, setChangingAddress] = useState(false)
  const [trailsPlaces, setTrailsPlaces] = useState(null)
  const [imagesNew, setImagesNew] = useState([])
  const [imagesToRemove, setImagesToRemove] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [trailsAssigned, setTrailsAssigned] = useState(1)

  const navigate = useNavigate()

  const {
    getUserPlace,
    getTrails,
    updatePlace,
    getFacilities,
    getTrailsPlaces,
    addPlaceImage,
    removePlaceImage,
    uploadProgress,
  } = useDb()
  const { currentUser } = useAuth()

  useEffect(() => {
    getUserPlace(currentUser.uid)
      .then((query) => {
        if (query.empty) return // Place created for the first time
        setPlace({ ...query.docs[0].data(), id: query.docs[0].id })
        setFacilitiesSelected(query.docs[0].data().facilities)
        setTrailsAssigned(query.docs[0].data().trails.length)
      })
      .catch((error) => {
        setError("Error getting place: ", error)
      })

    setRefresh(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.uid, refresh])

  useEffect(() => {
    getTrails()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setTrails((trails) => [...trails, doc.data()])
        })
      })
      .catch((error) => {
        setError("Error getting trails: ", error)
      })
    return () => {
      setTrails([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (place.trails) {
      const newTrailsPlaces = trailsPlaces ?? []
      for (let id = 0; id < place.trails.length; id++) {
        const trailsPlacesDb = getTrailsPlaces(place.trails[id])
        Object.entries(trailsPlacesDb).forEach(([name, places]) => {
          if (strToUrl(name) === place.trails[id]) {
            newTrailsPlaces[id] = places
          }
        })
      }
      setTrailsPlaces(newTrailsPlaces)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place.trails])

  useEffect(() => {
    getFacilities()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setFacilities((facilities) => [...facilities, doc.data().cs])
        })
      })
      .catch((error) => {
        setError("Error getting facilities: ", error)
      })
    return () => {
      setFacilities([])
    }
  }, [getFacilities])

  useEffect(() => {
    if (!autocompleteOpen) {
      setAutocompleteOptions([])
    }
  }, [autocompleteOpen])

  useEffect(() => {
    setValidationErrors({})
  }, [place])

  // Update place list for trail(s) selects
  const updateTrailPlaces = (id, trailName) => {
    const trailsPlacesDb = getTrailsPlaces(trailName)
    Object.entries(trailsPlacesDb).forEach(([name, places]) => {
      if (strToUrl(name) === trailName) {
        const newTrailsPlaces = trailsPlaces ?? []
        newTrailsPlaces[id] = places
        setTrailsPlaces(newTrailsPlaces)
      }
    })
  }

  // Autocomplete address
  useEffect(() => {
    let active = true

    // Start search after typing at least 3 characters
    if (autocompleteInputValue.length < 3) {
      return undefined
    }

    /* "https://api.mapy.cz/suggest/?count=5&bounds=48.5370786,12.0921668|51.0746358,18.8927040&category=address_cz,street_cz,municipality_cz,ward_cz&phrase=" */
    ;(async () => {
      const init = {
        headers: { "X-Mapy-Api-Key": MAPYCZ_API_KEY },
      }

      const response = await fetch(
        "https://api.mapy.cz/v1/suggest/?limit=5&locality=cz&type=regional.address&query=" +
        autocompleteInputValue.trim(),
        init
      )

      const responseJson = await response.json()

      const results = responseJson.items.map((res) => ({
        address:
          res.name +
          ", " +
          res.location,
        latitude: res.position.lat,
        longitude: res.position.lon
      }))

      if (active) {
        setAutocompleteOptions(results)
      }
    })()

    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteInputValue])

  async function handleSubmit(e) {
    e.preventDefault()

    let errors = {}

    if (Object.keys(autocompleteValue).length === 0 && !("address" in place)) {
      setError("Adresa nemůže zůstat prázdná")
      return
    }

    if (place.name === "" || place.name === undefined)
      errors.name = "Jméno nemůže zůstat prázdné"
    if (place.type === undefined) errors.type = "Zvol typ ubytování"
    if (place.type === "commercial" && place.ico === undefined)
      errors.ico = "Pro komerční ubytování je třeba vyplnit IČO"
    if (isNaN(place.capacity)) errors.capacity = "Zvol počet osob"
    if (place.capacity % 1 !== 0) errors.capacity = "Zvol celé číslo"
    if (place.capacity <= 0)
      errors.capacity = "Vyplň alespoň číslo 1, nebo vyšší"

    if (place.trails === undefined) place.trails = []
    if (place.trailsDistances === undefined) place.trailsDistances = []
    if (place.distances === undefined) place.distances = []

    for (let id = 0; id < trailsAssigned; id++) {
      if (place.trails[id] === undefined) {
        if (errors.trails === undefined) errors.trails = []
        errors.trails[id] = "Vyber trasu"
      }
      if (isNaN(place.trailsDistances[id]) || place.trailsDistances[id] < 0) {
        if (errors.trailsDistances === undefined) errors.trailsDistances = []
        errors.trailsDistances[id] = "Vyber vzdálenost od začátku trasy"
      }
      if (isNaN(place.distances[id]) || place.distances[id] < 0) {
        if (errors.distances === undefined) errors.distances = []
        errors.distances[id] = "Vyber vzdálenost od trasy"
      }
    }

    if (Object.keys(errors).length !== 0) {
      setError("Formulář obsahuje chyby")
      window.scrollTo(0, 0)
      setValidationErrors(errors)
      return
    }

    setLoading(true)
    setError("")

    // Convert newly created facilities to string
    const facilitesArray = facilitiesSelected.map((facility) =>
      typeof facility === "object" ? facility.label : facility
    )

    if (typeof place.hidden === "undefined") place.hidden = false

    const placeData = {
      name: place.name,
      type: place.type,
      capacity: parseInt(place.capacity),
      trails: place.trails,
      trailsDistances: place.trailsDistances,
      distances: place.distances,
      price: place.price || 0,
      phoneNumber: place.phoneNumber || "",
      email: place.email || "",
      address: autocompleteValue.address
        ? [
          autocompleteValue.address,
          0, // id is used for backward compatibility only
          autocompleteValue.latitude,
          autocompleteValue.longitude,
        ]
        : place.address,
      facilities: facilitesArray,
      details: place.details || "",
      detailsPrivate: place.detailsPrivate || "",
      web: place.web ? place.web.replace(/^https?:\/\//i, "") : "",
      hidden: place.hidden,
    }

    if (place.checkin) placeData.checkin = place.checkin
    if (place.checkout) placeData.checkout = place.checkout

    try {
      let pid = await updatePlace(currentUser.uid, placeData)

      if (place.id) pid = place.id

      for (const image of imagesToRemove) {
        await removePlaceImage(image.placeId, image.src)
      }
      setImagesToRemove([])

      for (const image of imagesNew) {
        await addPlaceImage(image.file, pid)
      }
      setImagesNew([])
    } catch (error) {
      setError("Nepodařilo se uložit data: " + error)
    } finally {
      setRefresh(true)
      setLoading(false)
      setSuccess("Uloženo")
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }
  }

  const handleChangingAddress = () => {
    setAutocompleteValue({})
    const { address, ...newPlace } = place
    setPlace(newPlace)
    setChangingAddress(true)
  }

  const handlePlaceChange = (event) => {
    const { name, value } = event.target
    setPlace((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handlePlaceTrailChange = (event, id) => {
    let newTrails = place.trails ?? []
    newTrails[id] = event.target.value

    setPlace((prevState) => ({
      ...prevState,
      trails: newTrails,
    }))

    updateTrailPlaces(id, event.target.value)
  }

  const handlePlaceTrailDistanceChange = (id, distance) => {
    let newTrailsDistances = place.trailsDistances ?? []
    newTrailsDistances[id] = distance

    setPlace((prevState) => ({
      ...prevState,
      trailsDistances: newTrailsDistances,
    }))
  }

  const handleDistanceChange = (id, distance) => {
    let newDistances = place.distances ?? []
    newDistances[id] = parseFloat(distance)
    setPlace({ ...place, distances: newDistances })
  }

  const handlePlacePriceChange = (event) => {
    const priceTemp =
      event.target.value === "free" ? -1 : place.price > 0 ? place.price : 0

    setPlace((prevState) => ({
      ...prevState,
      price: priceTemp,
    }))
  }

  const handleHiddenChange = (event) => {
    setPlace((prevState) => ({
      ...prevState,
      hidden: event.target.checked,
    }))
  }

  const handlePlaceCheckinChange = (event) => {
    setPlace((prevState) => ({
      ...prevState,
      checkin: event.target.value,
    }))
  }

  const handlePlaceCheckoutChange = (event) => {
    setPlace((prevState) => ({
      ...prevState,
      checkout: event.target.value,
    }))
  }

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setLoading(true)
      const imageFile = await resizeFile(e.target.files[0])
      const imageBase64 = await resizeBase64(e.target.files[0])
      setImagesNew([...imagesNew, { file: imageFile, base64: imageBase64 }])
      setLoading(false)
    }
  }

  const handleRemoveDbImageClick = (e, key) => {
    setImagesToRemove([
      ...imagesToRemove,
      { placeId: place.id, src: e.target.src },
    ])
    let imagesTemp = place.images
    imagesTemp.splice(key, 1)
    setPlace({ ...place, images: imagesTemp })
  }

  const handleRemoveTempImageClick = (key) => {
    let imagesTemp = imagesNew
    imagesTemp.splice(key, 1)
    setImagesNew([...imagesTemp])
  }

  function trailAssignment() {
    let assignmets = []

    // Remove last assigned trail
    function removeAssignment(id) {
      if (place.trails[id]) {
        const newTrails = place.trails
        newTrails.splice(id, 1)
        setPlace({ ...place, trails: newTrails })
      }

      if (trailsPlaces?.[id]) {
        const newTrailPlaces = trailsPlaces
        newTrailPlaces.splice(id, 1)
        setTrailsPlaces(trailsPlaces)
      }

      if (place.distances?.[id]) {
        const newDistances = place.distances
        newDistances.splice(id, 1)
        setPlace({ ...place, distances: newDistances })
      }

      if (place.trailsDistances?.[id]) {
        const newTrailsDistances = place.trailsDistances
        newTrailsDistances.splice(id, 1)
        setPlace({ ...place, trailsDistances: newTrailsDistances })
      }

      setTrailsAssigned(trailsAssigned - 1)
    }

    for (let id = 0; id < trailsAssigned; id++) {
      assignmets.push(
        <div key={id}>
          {id === trailsAssigned - 1 && id !== 0 && (
            <S.Right>
              <Button
                content="Odebrat tuto trasu"
                onClick={() => removeAssignment(id)}
                color="gray"
                size="small"
              />
            </S.Right>
          )}
          <S.TextFieldWrapper>
            <TextField
              label="Trasa"
              select
              value={
                place.trails
                  ? trails.some(
                    (trail) => strToUrl(trail.name) === place.trails[id]
                  )
                    ? place.trails[id] || ""
                    : ""
                  : ""
              }
              onChange={(e) => handlePlaceTrailChange(e, id)}
              required
              style={{ width: 230 }}
              variant="outlined"
              error={validationErrors?.trails?.[id] !== undefined}
              helperText={
                validationErrors?.trails?.[id] && validationErrors.trails[id]
              }
            >
              {trails &&
                trails.map((trail) => {
                  return (
                    <MenuItem
                      key={strToUrl(trail.name)}
                      value={strToUrl(trail.name)}
                      disabled={place.trails?.includes(strToUrl(trail.name))}
                    >
                      {trail.name}
                    </MenuItem>
                  )
                })}
            </TextField>
          </S.TextFieldWrapper>
          {place.trails !== undefined && trailsPlaces && trailsPlaces[id] && (
            <S.TextFieldWrapper>
              <TextField
                style={{ maxWidth: "calc(100vw - 40px)", minWidth: "300px" }}
                label="Místo na trase"
                select
                value={
                  place.trailsDistances
                    ? trailsPlaces[id].find((trailPlace) =>
                      trailPlace.km === place.trailsDistances[id]
                        ? place.trailsDistances[id] || ""
                        : ""
                    )?.km || 0
                    : ""
                }
                onChange={(e) =>
                  handlePlaceTrailDistanceChange(id, e.target.value)
                }
                required
                variant="outlined"
                error={validationErrors?.trailsDistances?.[id] !== undefined}
                helperText={
                  validationErrors?.trailsDistances?.[id] &&
                  validationErrors.trailsDistances[id]
                }
              >
                {trailsPlaces[id].map((trailPlace, index) => {
                  return (
                    <MenuItem
                      key={index + "-" + trailPlace.km}
                      value={trailPlace.km}
                    >
                      {trailPlace.km + " km - " + trailPlace.name}
                      {trailsPlaces[id].length > index + 1 &&
                        " ↔ " +
                        trailsPlaces[id][index + 1].km +
                        " km - " +
                        trailsPlaces[id][index + 1].name}
                    </MenuItem>
                  )
                })}
              </TextField>
              <FormHelperText id="details-helper-text">
                Ubytování se nachází mezi těmito dvěma body
              </FormHelperText>
            </S.TextFieldWrapper>
          )}
          <S.TextFieldWrapper>
            <TextField
              style={{ width: 300 }}
              type="number"
              label="Vzdálenost od trasy"
              name="distance"
              value={
                place.distances
                  ? isNaN(place.distances[id])
                    ? ""
                    : place.distances[id]
                  : ""
              }
              onChange={(e) => handleDistanceChange(id, e.target.value)}
              variant="outlined"
              required
              inputProps={{
                step: "0.1",
                lang: "en-US",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">km</InputAdornment>
                ),
              }}
              error={validationErrors?.distances?.[id] !== undefined}
              helperText={
                validationErrors?.distances?.[id] &&
                validationErrors.distances[id]
              }
            />
            <FormHelperText id="details-helper-text">
              Vzdálenost od nejbližšího bodu k tobě
            </FormHelperText>
          </S.TextFieldWrapper>
        </div>
      )
    }

    return assignmets
  }

  return (
    <>
      <Modal
        $visible={success !== ""}
        autoHide={5}
        toggle={() => setSuccess("")}
        type="success"
      >
        {success +
          (uploadProgress < 100 && uploadProgress > 0
            ? " " + uploadProgress + " %"
            : "")}
      </Modal>
      <Modal $visible={error !== ""} autoHide={5} toggle={() => setError("")}>
        {error}
      </Modal>
      <S.Main>
        <S.Title>Moje ubytovaní</S.Title>
        <S.TextFieldWrapper>
          <TextField
            label="Název ubytování"
            required
            variant="outlined"
            value={place.name || ""}
            onChange={handlePlaceChange}
            name="name"
            style={{ width: 300 }}
            error={"name" in validationErrors}
            helperText={"name" in validationErrors && validationErrors.name}
          />
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            label="Typ ubytování"
            select
            required
            variant="outlined"
            value={place.type || ""}
            onChange={handlePlaceChange}
            name="type"
            style={{ width: 300 }}
            error={"type" in validationErrors}
            helperText={"type" in validationErrors && validationErrors.type}
          >
            {accomodationTypes.map((type) => (
              <MenuItem key={type.textId} value={type.textId}>
                {type.title}
              </MenuItem>
            ))}
          </TextField>
        </S.TextFieldWrapper>
        {place.type === "commercial" && (
          <S.TextFieldWrapper>
            <TextField
              required
              name="ico"
              label="IČO"
              type="text"
              value={place.ico || ""}
              error={"ico" in validationErrors}
              helperText={"ico" in validationErrors && validationErrors.ico}
              onChange={handlePlaceChange}
              variant="outlined"
            />
          </S.TextFieldWrapper>
        )}
        <S.TextFieldWrapper>
          <TextField
            style={{ width: 180 }}
            label="Kapacita"
            type="number"
            required
            value={place.capacity || ""}
            onChange={handlePlaceChange}
            name="capacity"
            variant="outlined"
            error={"capacity" in validationErrors}
            helperText={
              "capacity" in validationErrors && validationErrors.capacity
            }
            InputProps={{
              inputProps: {
                min: 1,
              },
              endAdornment: (
                <InputAdornment position="end">Osob</InputAdornment>
              ),
            }}
          />
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            style={{ width: 300 }}
            label="Telefonní číslo"
            type="tel"
            value={place.phoneNumber ?? currentUser.phoneNumber ?? ""}
            onChange={handlePlaceChange}
            name="phoneNumber"
            variant="outlined"
            error={"phoneNumber" in validationErrors}
          />
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            style={{ width: 300 }}
            label="Email"
            type="email"
            value={place.email ?? currentUser.email ?? ""}
            onChange={handlePlaceChange}
            name="email"
            variant="outlined"
            error={"email" in validationErrors}
          />
        </S.TextFieldWrapper>
        {trailAssignment()}
        {trailsAssigned < 6 && (
          <S.Margin>
            <Button
              content="Přidat další trasu"
              onClick={() => setTrailsAssigned(trailsAssigned + 1)}
              color="gray"
              size="small"
            />
          </S.Margin>
        )}
        <S.TextFieldWrapper>
          <TextField
            label="Cena"
            select
            onChange={handlePlacePriceChange}
            value={place.price === -1 ? "free" : "fixed"}
            required
            variant="outlined"
          >
            <MenuItem value="free">Dobrovolný příspěvek</MenuItem>
            <MenuItem value="fixed">Pevná cena</MenuItem>
          </TextField>
        </S.TextFieldWrapper>
        {place.price !== -1 && (
          <S.TextFieldWrapper>
            <TextField
              type="number"
              label="Cena v Kč"
              name="price"
              disabled={place.price === -1 ? true : false}
              required={place.price === -1 ? false : true}
              value={place.price >= 0 ? place.price : ""}
              onChange={handlePlaceChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Kč/os</InputAdornment>
                ),
              }}
            />
          </S.TextFieldWrapper>
        )}
        <S.TextFieldWrapper>
          {typeof place.address !== "undefined" && !chnagingAddress ? (
            <div>
              {place.address[0]}
              <Button onClick={handleChangingAddress} color="white" blank>
                {" "}
                ✕
              </Button>
            </div>
          ) : (
            <Autocomplete
              open={autocompleteOpen}
              onOpen={() => {
                setAutocompleteOpen(true)
              }}
              onClose={() => {
                setAutocompleteOpen(false)
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              filterOptions={(x) => x}
              options={autocompleteOptions}
              required
              value={autocompleteValue}
              onChange={(event, newValue) => setAutocompleteValue(newValue)}
              noOptionsText="Začni ulicí a číslem ..."
              inputValue={autocompleteInputValue}
              onInputChange={(event, newValue) =>
                setAutocompleteInputValue(newValue)
              }
              getOptionLabel={(option) => option.address || ""}
              renderOption={(option) => option.address || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Adresa *"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>{params.InputProps.endAdornment}</Fragment>
                    ),
                  }}
                />
              )}
            />
          )}
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <Autocomplete
            style={{ maxWidth: "585px", minWidth: "300px" }}
            multiple
            freeSolo
            onChange={(event, values) => setFacilitiesSelected(values)}
            options={facilities}
            placeholder="Vyber ze seznamu nebo přidej vlastní ..."
            value={facilitiesSelected}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vybavení"
                variant="outlined"
                placeholder="Vyber ze seznamu nebo přidej vlastní ..."
              />
            )}
          />
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            name="checkin"
            label="Příchod"
            type="text"
            value={place.checkin || ""}
            onChange={handlePlaceCheckinChange}
            variant="outlined"
          />
          <FormHelperText id="details-helper-text">
            Např. odpoledne, nebo 11 hod.
          </FormHelperText>
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            name="checkout"
            label="Odchod"
            type="text"
            value={place.checkout || ""}
            onChange={handlePlaceCheckoutChange}
            variant="outlined"
          />
          <FormHelperText id="details-helper-text">
            Např. dopoledne, nebo 11 hod.
          </FormHelperText>
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            style={{ maxWidth: "585px", minWidth: "300px" }}
            name="details"
            label="Doplňující informace"
            multiline
            minRows={2}
            value={place.details || ""}
            onChange={handlePlaceChange}
            variant="outlined"
          />
          <FormHelperText id="details-helper-text">
            Uvidí všichni
          </FormHelperText>
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            style={{ maxWidth: "585px", minWidth: "300px" }}
            name="detailsPrivate"
            label="Doplňující kontaktní informace"
            multiline
            minRows={2}
            value={place.detailsPrivate || ""}
            onChange={handlePlaceChange}
            variant="outlined"
          />
          <FormHelperText id="details-private-helper-text">
            Viditelné pouze registrovaným
          </FormHelperText>
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <TextField
            label="Web"
            type="text"
            value={place.web || ""}
            onChange={handlePlaceChange}
            name="web"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">https://</InputAdornment>
              ),
            }}
          />
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={place.hidden || false}
                name="hidden"
                onChange={handleHiddenChange}
              />
            }
            label="Skrýt ubytování"
          />
        </S.TextFieldWrapper>
        <S.TextFieldWrapper>
          <h2>Vložit fotku</h2>
          <S.ImageContainer>
            {place?.images &&
              place.images.map((image, key) => (
                <S.ImageWrapper key={key}>
                  <S.Image
                    src={"https://" + image}
                    alt={"image " + key}
                    onClick={(e) => handleRemoveDbImageClick(e, key)}
                  />
                </S.ImageWrapper>
              ))}

            {imagesNew.map((image, key) => (
              <S.ImageWrapper key={"tempImage" + key}>
                <S.Image
                  src={image.base64}
                  alt={"image " + key}
                  onClick={() => handleRemoveTempImageClick(key)}
                />
              </S.ImageWrapper>
            ))}

            {
              // Images doesnt exists in db OR more then 5 images in db
              (typeof place?.images === "undefined" ||
                place.images.length + imagesNew.length < 5) && (
                <label>
                  <S.ImagePlaceholder>
                    {uploadProgress && uploadProgress < 100 ? (
                      uploadProgress
                    ) : loading ? (
                      <S.Spin>↻</S.Spin>
                    ) : (
                      "+"
                    )}
                  </S.ImagePlaceholder>
                  <S.HiddenInput
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </label>
              )
            }
          </S.ImageContainer>
        </S.TextFieldWrapper>
        <Grid item direciton="row">
          <Button
            onClick={() => navigate(-1)}
            disabled={loading}
            color="gray"
          >
            Zpět
          </Button>
          <Button disabled={loading} color="primary" onClick={handleSubmit}>
            Uložit
          </Button>
        </Grid>
      </S.Main>
    </>
  )
}
