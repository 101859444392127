import styled, { keyframes } from "styled-components"

export const Nav = styled.nav<{$blur: boolean}>`
${props => props.$blur && `filter: blur(10px);`}
  top: 0;
  left: auto;
  right: 0;
  position: sticky;
  height: 34px;
  z-index: 1100;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  background-color: white;
`

export const LogoContainer = styled.a`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;  
`

export const Logo = styled.img`
  width: 60px;
  height: 14px;
`

export const LogoTitle = styled.div`
  font-family: "Suisseintl";
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5;
`

export const MenuButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
`

export const IconMenu = styled.img`
  width: 28px;
  height: 28px;
  padding: 3px;
`

export const IconArrow = styled.img`
  width: 30px;
  height: 25px;
  margin-left: 10px
`

const slideFromRight = keyframes`
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(0);
  }
`

export const Menu = styled.div<{ $visible: boolean }>`
  ${(props) => !props.$visible && `display: none;`}
  position: absolute;
  top: ${props => props.theme.sizes.appBarHeight};
  right: 0;
  background-color: white;
  width: calc(100vw - 4rem);
  height: calc(100vh - ${props => props.theme.sizes.appBarHeight} - 4rem);
  padding: 2rem;
  border-left 2px solid ${(props) => props.theme.colors.gray};
  animation: ${slideFromRight} 0.2s ease-out;

  span {
    transition: margin-right 0.2s;
  }

  span:hover {
    margin-right: 0.5rem; 
  }

  ${(props) => props.theme.media.smUp} {
    max-width: 20rem;
  }
`

export const MenuItem = styled.div`
  font-family: "Suisseintl";
  font-weight: 700;
  font-size: 2.5rem;
  cursor: pointer;
  padding: 1rem 0;
  line-height: 1;
`

export const MenuItemTitle = styled.span``
