import React, { useContext, useState, useEffect } from "react"
import { db, storage } from "../firebase"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from "./AuthContext"

import strToUrl from "../helpers/strToUrl"
import trailsJson from "../admin/trails.json"
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { onSnapshot } from "firebase/firestore"

const DbContext = React.createContext()

export function useDb() {
  return useContext(DbContext)
}

export function DbProvider({ children }) {
  const { currentUser } = useAuth()
  const [loading, setLoading] = useState(true)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isNewMessage, setIsNewMessage] = useState(false)

  async function getTrails() {
    return await db.collection("trails").get()
  }

  function getFacilities() {
    return db.collection("facilities").get()
  }

  async function getTrail(trail) {
    return await db.collection("trails").doc(trail).get()
  }

  async function getTrailName(trail) {
    const trailName = await getTrail(trail).then((querySnapshot) => {
      return querySnapshot.data().name
    })
    return trailName
  }

  function getUser(uid) {
    return db.collection("users").doc(uid).get()
  }

  async function getUserAsync(uid) {
    return await db.collection("users").doc(uid).get()
  }

  /**
   * Gets users from uids
   * @param string[]
   * @returns {[uid: string]: user} 
   */
  async function getUsers(uids) {
    let users = {}

    for (let uid of uids) {
      const user = await getUserAsync(uid).then((querySnapshot) => {
        return querySnapshot.data()
      })
      users[uid] = user
    }

    return users
  }

  function getPlace(pid) {
    return db.collection("places").doc(pid).get()
  }

  function getUserPlace(uid) {
    return db.collection("places").where("uid", "==", uid).get()
  }

  async function createUser(
    uid,
    email,
    emailVerified = false,
    displayName = "",
    phoneNumber = "",
    photoURL = ""
  ) {
    const userDocRef = db.collection('users').doc(uid)
    const doc = await userDocRef.get()
    if (!doc.exists) {
      userDocRef.set({
        email: email,
        emailVerified: emailVerified,
        displayName: displayName,
        phoneNumber: phoneNumber,
        photoURL: photoURL,
      })
    }
    return doc
  }

  function updateUserPhoneNumber(uid, phoneNumber) {
    return db.collection("users").doc(uid).update({
      phoneNumber: phoneNumber,
    })
  }

  function updateUserDisplayName(uid, displayName) {
    return db.collection("users").doc(uid).update({
      displayName: displayName,
    })
  }

  async function userExists(uid) {
    const docRef = await db.collection("users").doc(uid)
    const user = await docRef.get()
    if (user.exists) {
      return true
    } else {
      return false
    }
  }

  function addPlaceToTrail(trail, pid) {
    return db
      .collection("trails")
      .doc(trail)
      .update({
        places: firebase.firestore.FieldValue.arrayUnion(pid),
      })
  }

  function removePlaceFromTrails(pid) {
    db.collection("trails")
      .where("places", "array-contains", pid)
      .get()
      .then((querySnapshot) =>
        querySnapshot.forEach((trail) => {
          db.collection("trails")
            .doc(trail.id)
            .update({
              places: firebase.firestore.FieldValue.arrayRemove(pid),
            })
        })
      )
  }

  function updateTrails(pid, trails) {
    db.collection("trails")
      .get()
      .then((querySnapshot) =>
        querySnapshot.forEach((trail) => {
          if (trails.includes(trail.id)) {
            // Add place to trail if listed in trails
            db.collection("trails")
              .doc(trail.id)
              .update({
                places: firebase.firestore.FieldValue.arrayUnion(pid),
              })
          } else {
            // Remove place from trail if not listed
            db.collection("trails")
              .doc(trail.id)
              .update({
                places: firebase.firestore.FieldValue.arrayRemove(pid),
              })
          }
        })
      )
  }

  function getTrailsPlaces() {
    return trailsJson
  }

  async function updatePlace(uid, placeData) {
    let pid = ""

    let querySnapshot = await getUserPlace(uid)

    // Create place with new pid if used haven't created place yet
    if (querySnapshot.empty) {
      pid = db.collection("places").doc().id
      placeData.uid = uid

      // Add place to trails collection
      placeData.trails.forEach((trail) => addPlaceToTrail(trail, pid))
      db.collection("places").doc(pid).set(placeData)

      return pid
    } else {
      // Place already exists
      if (placeData.hidden) {
        querySnapshot.forEach((place) => removePlaceFromTrails(place.id))
      } else {
        querySnapshot.forEach((place) =>
          updateTrails(place.id, placeData.trails)
        )
      }

      return db
        .collection("places")
        .where("uid", "==", uid)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs[0].ref.update(placeData)
        })
    }
  }

  async function addPlaceImage(image, pid) {
    let url = await uploadPlaceImage(image, pid)

    url = url.replace(/^https?:\/\//i, "")

    return await db
      .collection("places")
      .doc(pid)
      .update({
        images: firebase.firestore.FieldValue.arrayUnion(url),
      })
  }

  async function uploadPlaceImage(image, pid) {
    const timestamp = Date.now()

    const storageRef = ref(storage, `/place/${pid}_${timestamp}.${image.type.replace(/(.*)\//g, "")}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        setUploadProgress(progress)
      },
      (error) => {
        throw error
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(() => {
          setUploadProgress(0)
        })
      }
    )

    return await uploadTask.then((snapshot) => getDownloadURL(snapshot.ref))
  }

  async function removePlaceImage(pid, url) {
    try {
      await db
        .collection("places")
        .doc(pid)
        .update({
          images: firebase.firestore.FieldValue.arrayRemove(
            url.replace(/^https?:\/\//i, "")
          ),
        })

      await deletePlaceImage(url)
    } catch (err) {
      return err
    }

    return true
  }

  async function deletePlaceImage(url) {
    const imageUrl =
      "place/" + url.replace(/.+place%2F(.+).jpeg.+/, "$1") + ".jpeg"
    const imageRef = ref(storage, imageUrl);

    deleteObject(imageRef)
      .then(() => {
        return true
      })
      .catch((error) => {
        return error
      })
  }

  async function importTrails(trails) {
    // Delete all mark places
    let query = db.collection("places").where("mark", "==", true)
    await query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        // Delete place from trail colleciton
        removePlaceFromTrails(doc.id)
        // Delete place from places collection
        doc.ref.delete()
      })
    })

    // Create new places
    trails.forEach((trail) => {
      let trailName = strToUrl(trail[0])

      trail[1].forEach((place) => {
        // Creat place in places collection
        db.collection("places")
          .add({
            mark: true,
            name: place[0],
            trailsDistances: [place[1]],
          })
          .then((docRef) => {
            // Add place to trail collection
            addPlaceToTrail(trailName, docRef.id)
          })
      })
    })
  }

  async function getUserChats(uid) {
    const query = db.collection("chats").where("uids", "array-contains", uid)
    return await query.get()
  }

  async function createChat(uids) {

    // Check if chat already exists
    const query = db.collection("chats").where("uids", "==", uids)
    const querySnapshot = await query.get()
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id
    }

    // Create new chat
    const chatId = db.collection("chats").doc().id

    await db.collection("chats").doc(chatId).set({
      uids
    })

    return chatId
  }

  // Mark all user messages as read for given uid
  async function updateChatMessagesReadBy(chatId, uid) {
    const chatRef = db.collection("chats").doc(chatId)

    const chat = (await chatRef.get()).data()

    chat.messages = chat.messages.map((message) => {
      if (message.readBy.includes(uid)) return message
      else return { ...message, readBy: [...message.readBy, uid] }
    })

    return await chatRef.update({
      messages: chat.messages
    })
  }

  async function sendMessage(chatId, { sender, body }) {
    const chatRef = db.collection("chats").doc(chatId)
    return await chatRef.update({
      messages: firebase.firestore.FieldValue.arrayUnion({
        body,
        sender,
        created: firebase.firestore.Timestamp.now(),
        readBy: [sender]
      })
    })
  }

  // Listen for new messages in user chats
  useEffect(() => {
    if (!currentUser) return

    (async () => {
      const unsubscribe = onSnapshot(db.collection("chats").where("uids", "array-contains", currentUser.uid), async (querySnapshot) => {
        const isNew = querySnapshot.docs.some(chat => chat.data().messages?.some(message => !message.readBy.includes(currentUser.uid)))
        setIsNewMessage(isNew)
      })

      return () => unsubscribe()
    })()
  }, [currentUser])

  useEffect(() => {
    setLoading(false)
  }, [])

  const value = {
    getTrails,
    getFacilities,
    getTrail,
    getTrailName,
    getUser,
    getUsers,
    getUserPlace,
    getPlace,
    createUser,
    updateUserPhoneNumber,
    updateUserDisplayName,
    userExists,
    getTrailsPlaces,
    updatePlace,
    importTrails,
    addPlaceImage,
    removePlaceImage,

    getUserChats,
    sendMessage,
    createChat,
    updateChatMessagesReadBy,
    isNewMessage,

    uploadProgress,
  }

  return (
    <DbContext.Provider value={value}>
      {!loading && children}
    </DbContext.Provider>
  )
}
