import React, { useState, useEffect } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { useDb } from "../../contexts/DbContext"
import { useNavigate } from "react-router-dom"
import {
  TextField,
  FormHelperText,
} from "@material-ui/core"
import Button from "../../components/Button"
import Chip from "../../components/Chip"
import Modal from "../../primitives/modal"
import ModalPhoneUpdate from "../../primitives/modal-phone-update"
import * as S from "./styles"

export default function UserProfile() {
  const { currentUser, updatePassword, updateDisplayName, verifyEmail } =
    useAuth()
  const { updateUserDisplayName } = useDb()
  const [state, setState] = useState({
    displayName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    phoneNumber: "",
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const [showPhoneWizard, setShowPhoneWizard] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setState({
      ...state,
      displayName: currentUser.displayName || "",
      email: currentUser.email,
      phoneNumber: currentUser.phoneNumber,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const handleNameClick = () => {
    if (state.displayName === "") {
      setState({ ...state, displayName: currentUser.displayName })
      setError("Jméno nemůže zůstat prázdné")
      return
    }

    setLoading(true)
    if (state.displayName !== currentUser.displayName) {
      try {
        // Auth
        updateDisplayName(state.displayName.trim())
        // Db
        updateUserDisplayName(currentUser.uid, state.displayName.trim())
      } catch (error) {
        setError("Nepodařilo se změnit jméno: " + error)
      }
      setSuccess("Jméno bylo změněno")
    }
    setLoading(false)
  }

  const handleVerifyEmailClick = () => {
    setLoading(true)
    verifyEmail()
      .then(() => setSuccess("Odeslali jsme Ti e-mail se žádostí o potvrzení"))
      .catch(() => {
        setError(
          "Nepodařilo se odeslat ověřovací e-mail. Zkuste to prosím později."
        )
      })
      .finally(setLoading(false))
  }

  const handlePasswordClick = () => {
    setLoading(true)
    if (state.password !== state.passwordConfirm) {
      setError("Hesla nesouhlasí")
      setLoading(false)
      return
    } else if (state.password) {
      updatePassword(state.password)
        .then(() => {
          setSuccess("Heslo bylo změněno")
        })
        .catch((error) => {
          setError(
            error.code === "auth/requires-recent-login"
              ? "Pro změnu hesla je potřeba se znovu přihlásit"
              : "Nepodařilo se změnit heslo"
          )
        })
    }
    setLoading(false)
  }

  const handlePhoneClick = () => {
    if (!state.phoneNumber) return
    const phoneNumber = state.phoneNumber.replace(/-|\s/g, "")

    if (currentUser.phoneNumber && phoneNumber === currentUser.phoneNumber) {
      setError("Toto číslo je již ověřeno")
      return
    }

    if (/^\+[1-9]\d{10,14}$/.test(phoneNumber)) {
      setShowPhoneWizard(true)
    } else {
      setError("Nesprávný formát čísla");
    }
  }

  return (
    <>
      <Modal $visible={error !== ""} autoHide={5} toggle={() => setError("")}>
        {error}
      </Modal>
      <Modal
        $visible={success !== ""}
        autoHide={5}
        toggle={() => setSuccess("")}
        type="success"
      >
        {success}
      </Modal>
      {showPhoneWizard && (
        <ModalPhoneUpdate
          visible={true}
          close={() => setShowPhoneWizard(false)}
          phoneNumber={state.phoneNumber.replace(/\s+/g, "") || false}
        />
      )}
      <S.Main>
          <S.Title data-uid={currentUser.uid}>Nastavení profilu</S.Title>
          <S.TextFieldWrapper>
            <TextField
              type="text"
              variant="outlined"
              value={state.displayName}
              onChange={(e) =>
                setState({ ...state, displayName: e.target.value })
              }
              label="Jméno a příjímení"
              required
            />
          </S.TextFieldWrapper>
          <Button onClick={handleNameClick} disabled={loading}>
            Změnit jméno
          </Button>
          <S.Divider />
          <S.TextFieldWrapper>
            <S.FormRow>
              <TextField
                type="email"
                variant="outlined"
                label="E-mail"
                value={state.email}
                disabled
              />
              <Chip
                content={currentUser.emailVerified ? "Ověřený" : "Neověřený"}
                color="gray"
              />
            </S.FormRow>
          </S.TextFieldWrapper>
          {!currentUser.emailVerified && (
            <Button onClick={handleVerifyEmailClick} disabled={loading}>
              Ověřit e-mail
            </Button>
          )}
          <S.Divider />
          <S.TextFieldWrapper>
            <TextField
              type="password"
              variant="outlined"
              label="Heslo"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
              autoComplete="new-password"
            />
            {state.password !== "" && state.password.length < 8 && (
              <FormHelperText id="details-helper-text">
                Heslo musí mít alespoň 8 znaků
              </FormHelperText>
            )}
          </S.TextFieldWrapper>
          <S.TextFieldWrapper>
            <TextField
              type="password"
              variant="outlined"
              label="Heslo znovu"
              value={state.passwordConfirm}
              onChange={(e) =>
                setState({ ...state, passwordConfirm: e.target.value })
              }
              autoComplete="new-password"
            />
          </S.TextFieldWrapper>
          <Button onClick={handlePasswordClick} disabled={loading}>
            Změnit heslo
          </Button>
          <S.Divider />
          <S.TextFieldWrapper>
            <S.MuiPhoneNumber
              style={{ width: 220 }}
              label="Telefon"
              variant="outlined"
              disableAreaCodes={true}
              defaultCountry={"cz"}
              value={state.phoneNumber}
              onChange={(value) => setState({ ...state, phoneNumber: value })}
            />
          </S.TextFieldWrapper>
          <Button onClick={handlePhoneClick} disabled={loading}>
            Změnit telefon
          </Button>
          <S.Divider />
          <Button
            onClick={() => navigate("/")}
            disabled={loading}
            color="gray"
          >
            Zpět
          </Button>
      </S.Main>
    </>
  )
}
