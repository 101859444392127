import React from "react"
import Signup from "./pages/signup"
import { AuthProvider } from "./contexts/AuthContext"
import { DbProvider } from "./contexts/DbContext"
import { LocalProvider } from "./contexts/LocalContext"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
//import { csCZ } from "@mui/material/locale"
import theme from "./theme"
import Dashboard from "./pages/dashboard"
import Login from "./pages/login"
import PrivateRoute from "./pages/PrivateRoute"
import ForgotPassword from "./pages/forgot-password"
import UserProfile from "./pages/user-profile"
import HostProfile from "./pages/host-profile"
import Trail from "./pages/trail"
import Place from "./pages/place"
import Partners from "./pages/partners"
import Navigation from "./parts/navigation"
import Admin from "./pages/admin"
//import Chats from "./pages/chats"
//import Chat from "./pages/chats/chat"
//import NewChat from "./pages/chats/new"
import Footer from "./parts/footer"
import * as S from "./App.styles"


const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#CFB023",
      contrastText: "#212322"
    },
    warning: {
      main: "#ffaaaa",
    },
  },
  typography: {
    fontFamily: [
      'Suisseintl',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  //csCZ,
})

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <DbProvider>
          <LocalProvider>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <S.Container>
                  <Navigation />
                  <Routes>
                    <Route
                      path="/user-profile"
                      element={<PrivateRoute />}
                    >
                      <Route path="/user-profile" element={<UserProfile />} />
                    </Route>
                    <Route
                      path="/host-profile"
                      element={<PrivateRoute />}
                    >
                      <Route path="/host-profile" element={<HostProfile />} />
                    </Route>
                    <Route
                      path="/trail"
                      element={<PrivateRoute />}
                    >
                      <Route path="/trail/:trailId" element={<Trail />} />
                    </Route>
                    <Route
                      path="/place/"
                      element={<PrivateRoute />}
                    >
                      <Route path="/place/:placeId/:trailId?" element={<Place />} />
                    </Route>
                    <Route
                      path="/admin"
                      element={<PrivateRoute />}
                    >
                      <Route path="/admin" element={<Admin />} />
                    </Route>
                    <Route
                      path="/partners"
                      element={<PrivateRoute />}
                    >
                      <Route path="/partners" element={<Partners />} />
                    </Route>
{/*                     <Route
                      path="/chats"
                      element={<PrivateRoute />}
                    >
                      <Route path="/chats/" element={<Chats />} />
                      <Route path="/chats/new/:uid" element={<NewChat />} />
                      <Route path="/chats/:chatId" element={<Chat />} />
                    </Route> */}
                    <Route path="/signup" element={<Signup />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                    <Route
                      path="/"
                      element={<PrivateRoute />}
                    >
                      <Route path="/:trailSetId?" element={<Dashboard />} />
                    </Route>
                  </Routes>
                  <Footer />
                </S.Container>
              </ThemeProvider>
            </MuiThemeProvider>
          </LocalProvider>
        </DbProvider>
      </AuthProvider>
    </Router>
  )
}
