import styled from "styled-components"

export const Main = styled.main`
  max-width: ${(props) => props.theme.sizes.containerWidth};
  margin: auto;
  padding: 0 2rem;
`

export const Title = styled.h1`
  margin-top: 2rem;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    width: 100%;
  }

  ${(props) => props.theme.media.mdUp} {
    margin-top: 2rem;
    max-width: 1320px;
    margin: 2rem auto 0 auto;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 30rem;
  object-fit: cover;
`

export const Avatar = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
`
export const FormRow = styled.div`
  margin-bottom: 1rem;
`

export const Red = styled.span`
  color: var(--danger);
`
export const ListItem = styled.li`
  display: flex;
  align-items: start;
  gap: 0.75rem;
  margin-bottom: 1rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  line-height: 1.2;
`
export const ListValue = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ListKey = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
`

export const FullscreenOpen = styled.a`
  position: relative;
  display: block;
  margin: 1rem 0 2rem auto;
`

export const ArrowRight = styled.span`
  padding-left: 0.1rem;

  > svg {
    height: 0.75em;
    polygon {
      fill: ${(props) => props.theme.colors.black};
    }
  }
`

export const ArrowLeft = styled.span`
  padding-right : 0.1rem;

  > svg {
    height: 0.75em;
    polygon {
      fill: ${(props) => props.theme.colors.black};
    }
  }
`

export const Black = styled.span`
  color: ${(props) => props.theme.colors.black};
`

export const Gray = styled.span`
  color: ${(props) => props.theme.colors.grayDark};
`
export const MapContainer = styled.div`
  position: relative;
  border-radius: 1rem;
  overflow: hidden;

  > div {
    height: 500px !important;

    ${(props) => props.theme.media.smUp} {
      height: 600px !important;
    }
  }
`

export const MapyCzAttr = styled.a`
  position: absolute;
  z-index: 999;
  bottom: 20px;
  right: 20px;
`

export const Hr = styled.hr`
  margin: 2rem -2rem;
`

export const DetailsList = styled.ul`
  margin-bottom: 2rem;

  > li {
    line-height: 1.5;
  }
`
export const PDetails = styled.p`
  margin-bottom: 2rem;
`

export const TrailName = styled.span`
  color: ${props => props.theme.colors.grayDark};
  margin: 0 1em 0 0.25em;
`

export const MessageButton = styled.span`
  margin-left: 1rem;
`